import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "./auth/auth.guard";
import { AutoLoginGuard } from "./services/auto-login.guard";

export const routes: Routes = [
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canLoad: [AuthGuard],
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./pages/policy-privacy/policy-privacy.module").then(
        (m) => m.PolicyPrivacyModule
      ),
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/login/login.module')
  //     .then(m => m.LoginModule),
  // },
  
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    canLoad: [AutoLoginGuard],
  },

  { path: "", redirectTo: "", pathMatch: "full" },
  { path: "**", redirectTo: "" },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
