import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-loader',
  templateUrl: './data-loader.component.html',
  styleUrls: ['./data-loader.component.scss'],
})
export class DataLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
