import { Injectable } from "@angular/core";
import { CanLoad, Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, take, map } from "rxjs/operators";
import { AdminService } from "./admin.service";

@Injectable({
  providedIn: "root",
})
export class AutoLoginGuard implements CanLoad {
  constructor(private adminService: AdminService, private router: Router) {}
  canLoad(): Observable<boolean> {
    return this.adminService.isAuthenticated.pipe(
      filter((val) => val !== null),
      take(1),
      map((isAuth) => {
        if (isAuth) {
          this.router.navigate(["/pages"]);
        } else {
          return true;
        }
      })
    );
  }
}
