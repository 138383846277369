import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToasterService } from "../../pages/shared/components/toaster.service";
import { AdminService } from "../../services/admin.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router,private toasterService:ToasterService, private adminService:AdminService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes("Login") ||
      localStorage.getItem("userDetails") == null ||
      localStorage.getItem("userDetails") == undefined
    ) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error)
          let errorMsg = "";
          if (error.status === 401) {
            this.presentAlert();
            this.adminService.isAuthenticated.next(false);
            localStorage.clear();
            this.router.navigate([""]);
          }
          return throwError(errorMsg);
        })
      );
    } else {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${userDetails.AccessToken}`,
        },
      });
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = "";
          if (error.status == 401) {
            this.adminService.isAuthenticated.next(false);
            localStorage.clear();
            this.router.navigate([""]);
            this.presentAlert();
            ;
          }
          return throwError(errorMsg);
        })
      );
    }
  }
  async presentAlert() {
    this.toasterService.showToast("danger", "Expired", 'Session Expired');
  }
}
