import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class Constants {
  public loginUrl = environment.apiBaseUrl + 'Account/Login';
  public registerURL = environment.apiBaseUrl + 'Account/';
  public agentUrl = environment.apiBaseUrl + 'Master/getAgents';
  public invitationUrl = environment.apiBaseUrl + 'Invitation/GetInvitationsForAdmin?';
  public propertyListUrl = environment.apiBaseUrl + 'Project/GetProjectList?skip=0&take=100' ;
  public propertyDetailsUrl = environment.apiBaseUrl +'Project/GetProjectDetails?ProjectId=' ;
  public amenitiesUrl = environment.apiBaseUrl + 'Amenities/GetAmenities';
  public projectPostUrl = environment.apiBaseUrl + 'Project/SaveProject';
  public projectUpdateUrl = environment.apiBaseUrl + 'Project/UpdateProject'
  public projectBrokerageUrl = environment.apiBaseUrl + 'Project/AddPartnerBrokerage';
  public projectDistanceUrl = environment.apiBaseUrl + 'Project/AddProjectToDistance';
  public projectContactUrl = environment.apiBaseUrl + 'Project/AddProjectContact';
  public projectImageUpload = environment.apiBaseUrl + 'Project/AddProjectContact';
  public SaveProjectProperty = environment.apiBaseUrl + 'ProjectProperties/SaveProjectProperty';
  public UpdateProjectProperty = environment.apiBaseUrl + 'ProjectProperties/UpdateProjectProperty';
  public DeleteProjectProperty= environment.apiBaseUrl + 'ProjectProperties/DeleteProjectProperty';
  public DeleteProject= environment.apiBaseUrl + 'Project/RemoveProject?ProjectId=';
  public DeleteProjectImage= environment.apiBaseUrl + 'Project/DeleteProjectImage?ImageId=';
  public GetProjectProperties = environment.apiBaseUrl + 'ProjectProperties/GetProjectProperties';
  public dropdownCalls= environment.apiBaseUrl +'Master/';
  public uploadImageUrlProperty=environment.apiBaseUrl +"Project/SaveProjectImage?ProjectId=";
  public getImageUrlProperty=environment.apiBaseUrl +"Project/GetProjectImages?ProjectId=";
  public imageUrlProperty=environment.imgBaseUrl+"PropertyImages/";
  public imageUrlProject=environment.imgBaseUrl+"projectimages/";
  public getAppUsersById= environment.apiBaseUrl +'Account/GetUsersByUserTypeId?UserTypeId=';
  public updateUser= environment.apiBaseUrl +'Account/UpdateUserFromAdmin';
  public removeUser= environment.apiBaseUrl +'Account/RemoveUser?UserId=';
  public getOwnerProperties= environment.apiBaseUrl +'OwnerProperty/GetOwnerProperties?';
  public updateOwnerProperty= environment.apiBaseUrl +'Property/UpdateProperty';
  public removeProperty= environment.apiBaseUrl +'Property/RemoveProperty?PropertyMastId=';
  public getAgentPropertyList= environment.apiBaseUrl +'AgentProperty/GetAgentPropertiesByStatusType?PropStatusTypeId=';
  public legalServiceLeads= environment.apiBaseUrl +'CustomerServices/GetLegalServiceLeads?';
  public loanServiceLeads= environment.apiBaseUrl +'CustomerServices/GetLoanServiceLeads?';
  public lastTransactionList= environment.apiBaseUrl +'PropertyTransaction/GetTransactionList?';
  public propertyImage= environment.apiBaseUrl +'Property/savePropertyImage?propertyId=';
  public societyURL= environment.apiBaseUrl +'Society/';
  public areaURL= environment.apiBaseUrl +'Area/';
  public addPropertyUR= environment.apiBaseUrl +'Property/AddProperty';
  public updatePropertyUR= environment.apiBaseUrl +'Property/UpdateProperty';
  public FilterUsersByUserTypeId= environment.apiBaseUrl +'Account/FilterUsersByUserTypeId';
  public FilterAgentProperties= environment.apiBaseUrl +'AgentProperty/FilterAgentProperties';
  public FilterCustomerPropertyList= environment.apiBaseUrl +'CustomerProperty/FilterCustomerPropertyList';
  public FilterOwnerPropertyList= environment.apiBaseUrl +'AgentProperty/FilterOwnerPropertyList';
  public GetUserCountDetails= environment.apiBaseUrl +'Users/GetUserCountDetails';
  public GetPropertyCountDetails= environment.apiBaseUrl +'Property/GetPropertyCountDetails';
  public GetUserCountDetailList= environment.apiBaseUrl +'Users/GetUserCountDetailList?CountType=';
  public GetDataByAgentId= environment.apiBaseUrl +'AgentData/GetDataByAgentId?agentId=';
  public SaveAgentData= environment.apiBaseUrl +'AgentData/SaveAgentData';
  public GetAgentDataHistory= environment.apiBaseUrl +'AgentData/GetAgentDataHistory?agentId=';
  public GetAgentDataDetailCount= environment.apiBaseUrl +'AgentData/GetAgentDataDetailCount';
  public GetAgentDataDetails= environment.apiBaseUrl +'AgentData/GetAgentDataDetails?CountType=';
  public UsersSubscriptionList= environment.apiBaseUrl + "Subscription/UsersSubscriptionList?UserId="
}
