<div class="file-input-container" style="text-align: center;">
  <!-- <input type="file" ng2FileSelect [uploader]="uploader"  multiple />
    Upload Images
  </label> -->
  <label>
    <input type="file" ng2FileSelect accept="image/*" [uploader]="uploader" multiple>
    Upload Images
  </label>
</div>

<span style="font-size: 20px;font-weight: bold;color: #777777;">Image: {{ uploader?.queue?.length }}</span>

<mat-list>
  <mat-list-item (ionItemReorder)="reorderFiles($event)" disabled="false">

    <div *ngFor="let item of uploader.queue">
      <img style="height: 100px;width: 100;" [src]="filePreviewPath" alt="">
      <mat-label>
        {{ item?.file?.name }}
      </mat-label>

      <button type="button" class="btn btn-danger 42-xs" (click)="item.remove()">
        <span class="glyphicon glyphicon-trash"></span>
        Remove
      </button>
    </div>
  </mat-list-item>
</mat-list>