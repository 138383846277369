import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'societyFilter'
})
export class SocietyFilterPipe implements PipeTransform {

  transform(societyList: any[], societyfilter: string): any {

    if (!societyList || !societyfilter) {
        return societyList;
    }

    return societyList.filter(item => item.SocietyName.toLowerCase().indexOf(societyfilter.toLowerCase()) !== -1);
}

}
